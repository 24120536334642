import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import { relatedShowcase } from '~/queries/pageQuery' // eslint-disable-line
import Layout from '../components/layout'
import ContentBuilder from '../components/ContentBuilder'

class PagesPost extends Component {
  render() {
    const {
      data: {
        craft: { post },
      },
    } = this.props

    return (
      <Layout location={this.props.location}>
        <div className="reading w-full md:w-2/3 mx-auto my-16 px-4 md:px-0">
          <h1 className="text-3xl uppercase text-center font-graphik font-black mb-12">
            {post.title}
          </h1>

          <div className="my-8">
            <ContentBuilder data={post} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default PagesPost

export const pageQuery = graphql`
  query PagesPostByUri($uri: String!) {
    craft {
      post: entry(uri: $uri) {
        ...relatedPages
        ... on Craft_Pages {
          matrixBuilder {
            ... on Craft_MatrixBuilderCopy {
              body {
                totalPages
                content
              }
            }
            ... on Craft_MatrixBuilderImage {
              image {
                id
                url
              }
              hideOnMobile
            }

            ... on Craft_MatrixBuilderImageArray {
              images {
                url
              }
            }
          }
        }
      }
    }
  }
`
