/* global tw */
import React from 'react'
import styled from 'styled-components'

const Body = styled.div`
	${tw`text-xl`};

	h2 {
		font-size: 40px;
		line-height: 1.1;
		margin-bottom: 5rem;
		position: relative;
		&:after {
			content: '';
			position: relative;
			width: 110px;
			border-bottom: 1px solid #fff;
			display: block;
			margin-top: 30px;
		}
	}

	p.q {
		font-size: 26px;
		line-height: 1.33;
		margin-bottom: 10px;
		margin-top: 2rem;
`

const Text = ({ block, ...props }) => {
  return block.body ? (
    <Body dangerouslySetInnerHTML={{ __html: block.body.content }} />
  ) : null
}

export default Text
