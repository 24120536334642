/* global tw */
import React from 'react'
import styled from 'styled-components'

const TestimonialBlock = styled.div`
  ${tw`block bg-grey-darkest text-center py-8 lg:py-32`};
`

const Quote = styled.h2`
  ${tw`text-4xl lg:text-5xl font-black text-white relative`};
  &:before {
    content: '“';
    font-family: helvetica;
    font-weight: bold;
    text-align: center;
    font-size: 96px;
    line-height: 48px;
    display: block;
  }
`

const Source = styled.h3`
  ${tw`text-2xl uppercase text-black`};
`

const Testimonial = ({ block, ...props }) => {
  return (
    <TestimonialBlock>
      <Quote>{block.quote}</Quote>
      <Source>{block.otherSource}</Source>
    </TestimonialBlock>
  )
}

export default Testimonial

// <p>
// <em>heading block</em>
// </p>

// position: absolute;
// width: 50px;
// height: 50px;
// background: red;
// left: 0;
// top: 0;
