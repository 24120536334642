import React from 'react'
// import { graphql } from "gatsby";

const ImageArray = ({ block, ...props }) => {
  return block.images ? (
    block.images.map((image, i) => (
      <div key={i} className="w-1/2 px-4">
        <img
          src={image.url + '?w=608&h=608&fit=crop&crop=faces&facepad=5'}
          alt=""
          className="img-fluid w-full"
        />
      </div>
    ))
  ) : (
    <h4>frankensalts</h4>
  )
}

export default ImageArray
