/* global tw */
import React from 'react'
import HeadingBlock from './Blocks/Heading'
import ImageBlock from './Blocks/Image'
import ImageArrayBlock from './Blocks/ImageArray'
import TextBlock from './Blocks/Text'
import BlockquoteBlock from './Blocks/BlockquoteBlock'
import TestimonialBlock from './Blocks/Testimonial'
import styled from 'styled-components'

// if inletted set to this
// <div className="flex flex-wrap -mx-4">
// <div className="w-full md:w-3/4 px-4 ml-auto">
// </div></div>

const Fwrap = styled.div`
  ${tw`flex flex-wrap -mx-4`};
`

const ThreeQuarter = styled.div`
  ${tw`w-full md:w-3/4 px-4 mx-auto`};
`

const ContentBuilder = ({ data, theming }) => (
  <React.Fragment>
    {data.matrixBuilder.map((block, index) => {
      switch (block.__typename) {
        case 'Craft_MatrixBuilderCopy':
          return (
            <Fwrap
              key={index}
              className={
                theming == 'dark'
                  ? 'py-12 text-white leading-loose'
                  : 'py-12 text-black leading-loose'
              }
            >
              <ThreeQuarter>
                <TextBlock block={block} key={`${block.__typename}-${index}`} />
              </ThreeQuarter>
            </Fwrap>
          )
        case 'Craft_MatrixBuilderImage':
          return (
            <ImageBlock
              block={block}
              key={`${block.__typename}-${index}`}
              width="400"
            />
          )

        case 'Craft_MatrixBuilderTestimonial':
          return (
            <TestimonialBlock
              block={block}
              key={`${block.__typename}-${index}`}
            />
          )

        case 'Craft_MatrixBuilderImageArray':
          return (
            <Fwrap key={index}>
              <ImageArrayBlock
                block={block}
                key={`${block.__typename}-${index}`}
                width="400"
              />
            </Fwrap>
          )

        case 'Craft_MatrixBuilderCodeBlocks':
          return (
            <div
              block={block}
              key={`${block.__typename}-${index}`}
              className="bg-purple p-6"
            >
              <div className="text-6xl text-white">CodeBlocks:</div>
            </div>
          )
        default:
          return null
      }
    })}
  </React.Fragment>
)
export default ContentBuilder
