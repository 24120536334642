import React from 'react'
// import { graphql } from "gatsby";

const Image = ({ block, ...props }) => {
  return block.image ? (
    <div className="bg-purple w-full">
      <img
        src={
          block.image[0]
            ? block.image[0].url +
              '?sat=-100&w=608&h=342&fit=crop&crop=faces&facepad=5'
            : null
        }
        alt=""
        className="img-fluid w-full"
      />
    </div>
  ) : null
}

export default Image
